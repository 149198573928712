.app-menu-left {
  width: 256px;
  height: 100vh;
  background-color: var(--menu-background-color);
  display: flex;
  flex-direction: column;
  position: fixed;

  background-repeat: no-repeat;
  background-size: cover;
  background-image: var(--background-image);

  --darker-menu-color: color-mix(in srgb, var(--menu-text-color) 60%, black);
  
  .logo-wrapper {
    padding: 64px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    img {
      object-fit: contain;
      height: 50px;
    }
  }

  .menu-list {
    margin-left: 30px;
    list-style: none;
    
    & > a {
      color: var(--menu-text-color-not-focused);
      font-size: var(--fontSize-14);
      font-weight: var(--weight-600);
      text-decoration: none;
      display: flex;
      align-items: center;
      transform-origin: center;
      transition: transform .1s;
      height: 45px;

      svg {
        margin-right: 10px;
        width: 14px;
        fill: var(--darker-menu-color);

        path {
          fill: var(--darker-menu-color);
        }
      }
    }

    .leftMenuBarMonitorIcon {
      path:first-child {
        fill: none;
      }
      path:first-child,
      path:nth-child(2) {
        stroke: var(--darker-menu-color);
      }
      rect {
        fill: var(--darker-menu-color);
      }
    }

    a.active {
      color: var(--menu-text-color);
      svg, path {
        fill: var(--menu-text-color);
      }

      .leftMenuBarMonitorIcon {
        path:nth-child(1),
        path:nth-child(2) {
          stroke: var(--menu-text-color) !important;
        }
        rect {
          fill: var(--menu-text-color);
        }
      }
    }

    a:hover {
      transform: translateX(8px) scale(1.1);
    }
  }
}

.menu-list-supplier-presentation-icon {
  width: 20px !important;
  height: 20px !important;
  margin-left: 8px;
  * {
    fill: var(--sunglowYellow) !important;
    fill: var(--red-500) !important;
  }
}