.signup {
  margin: 16px auto;
  width: 714px;
  color: var(--gray-700);

  h1 {
    color: var(--gray-900);
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
  }

  p {
    font-size: 14px;
  }
}